import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import { useAppDispatch } from '../../config/hook';
import { DataTableLoader } from '../Loader';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {addUnavailability } from '../../redux/action';
import { fromDateToString, DateFormats } from '../../utils';
import { DateTimeInput } from '../../uiComponents';

export const AddUnavailability = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [title, setTitle] = useState('');
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [isInValidUnavailabilityName, setisInValidUnavailabilityName] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.isAddClick && isInValidUnavailabilityName===false) {
      const requestPayload = {
        interpreterId: props?.userId,
        name: title,
        frequency: 206500000,
        fromDate: `${fromDateToString(startDate, DateFormats.DYNAMICSEARCHFORMAT)} ${fromDateToString(startTime, DateFormats.TIME)}`,
        toDate: `${fromDateToString(endDate, DateFormats.DYNAMICSEARCHFORMAT)} ${fromDateToString(endTime, DateFormats.TIME)}`,
      };
      addUnavailability(requestPayload, dispatch);
    }
  }, [props.isAddClick,props.refreshState]);

  useEffect(() => {
    if (Object.keys(props?.addResponse).length > 0) {
      const responsePayload = props?.addResponse;
      if (responsePayload.status === 200) {
        props.onCustomHide();
        window.location.reload();
      }
    }
  }, [props.addResponse]);

  const handleOnTitle = (e: any) => {
    setTitle(e.target.value);
    if (e.target.value) {
      setisInValidUnavailabilityName(false);
    } else {
      setisInValidUnavailabilityName(true);
    }
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    setEndDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };
  const handleStartTime = (date) => {
    setStartTime(date);
    setEndTime(date);
  };
  const handleEndTime = (date) => {
    setEndTime(date);
  };

  if (props.loading) return <DataTableLoader />;

  return (
    <Form>
      <Form.Group className="mb-3" as={Row}>
        <Form.Label column sm="3" className="form-label blue font_12">
          {t('unAvailability.table.name')}
        </Form.Label>
        <Col sm="9">
          <Form.Control type="text" placeholder={t('unAvailability.table.name')} onChange={handleOnTitle} isInvalid={isInValidUnavailabilityName} required/>
          <Form.Control.Feedback type="invalid">
          {t('unAvailability.fraværValidation')}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group className="mb-3" as={Row}>
        <Form.Label column sm="3" className="form-label blue font_12">
          {t('unAvailability.table.start')}
        </Form.Label>
        <Col sm="9">
          <DateTimeInput
            selected={startDate}
            onChangeDate={handleStartDate}
            datePickerClass="form-control date-time blue font_12"
            dateFormat="dd.MM.yyyy"
            selectedTime={startTime}
            onChangeTime={handleStartTime}
            datePickerClassTime="form-control date-time blue font_12"
            dateFormatTime="HH:mm"
          />
        </Col>
      </Form.Group>
      <Form.Group className="mb-3" as={Row}>
        <Form.Label column sm="3" className="form-label blue font_12">
          {t('unAvailability.table.end')}
        </Form.Label>
        <Col sm="9">
          <DateTimeInput
            selected={endDate}
            onChangeDate={handleEndDate}
            datePickerClass="form-control date-time blue font_12"
            dateFormat="dd.MM.yyyy"
            selectedTime={endTime}
            onChangeTime={handleEndTime}
            datePickerClassTime="form-control date-time blue font_12"
            dateFormatTime="HH:mm"
          />
        </Col>
      </Form.Group>

    </Form>
  );
};


function mapStateToProps(state) {
  const { common } = state;
  return {
    loading: common.loading,
    error: common.error,
    errorResponse: common.errorResponse,
    addResponse: common.unavailabilityAdd,
  };
}

export const AddUnavailabilityWrapper = connect(mapStateToProps)(AddUnavailability);
